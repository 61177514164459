*,
body {
  font-family: "Lexend", sans-serif;
}

.ps-sidebar-container {
  border-radius: 15px;
}

.css-9zl2q6 {
  height: fit-content !important;
}

.ant-picker-input .ant-picker-suffix {
  display: none;
}
.ant-picker-focused {
  box-shadow: none !important;
}
.ant-picker-input input {
  cursor: pointer;
}
.ant-picker-input input::placeholder {
  color: black;
  opacity: 1;
}

.snippet-scroll pre {
  overflow-x: scroll;
}
.snippet-scroll pre::-webkit-scrollbar {
  display: none;
}

.dashboard .ant-spin-dot-item {
  background-color: white;
}
